<template>
  <section class="cta-subscribe pb-120 pt-100">
    <div class="container">
      <div
        class="bg-gradient ptb-120 position-relative overflow-hidden rounded-custom"
      >
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8">
            <div class="subscribe-info-wrap text-center position-relative z-2">
              <div class="section-heading" data-aos="fade-up" data-aos-delay="50">
                <h4 class="h5 text-warning">我们试试看,获得免费支持</h4>
                <h2>现在注册获得7天免费的技术支持</h2>
                <p>
                  用专业的软件完成数字化经营最后一步.
                </p>
              </div>
              <div data-aos="fade-up"
                   data-aos-delay="50" class="form-block-banner mw-60 m-auto mt-5">
                <router-link to="/comingSoon" class="btn btn-primary"
                  >进行注册</router-link
                >
              </div>
              <ul
                  data-aos="fade-up"
                  data-aos-delay="50"
                class="nav justify-content-center subscribe-feature-list mt-4"
              >
                <li class="nav-item">
                  <span
                    ><i class="far fa-check-circle text-primary me-2"></i>7天免费试用</span
                  >
                </li>
                <li class="nav-item">
                  <span
                    ><i class="far fa-check-circle text-primary me-2"></i
                    > 24/7技术支持</span
                  >
                </li>
                <li class="nav-item">
                  <span
                    ><i class="far fa-check-circle text-primary me-2"></i>可随时取消</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"
        ></div>
        <div
          class="bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import GLightbox from 'glightbox';
export default {
  mounted() {
    //lightbox settings
    this.lightbox = GLightbox({
      href: 'https://www.youtube.com/watch?v=hAP2QF--2Dg',
      type: 'video',
      source: 'youtube',
      width: 800,
      autoplayVideos: true,
    });
  },
};
</script>
