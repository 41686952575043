<template>
<div>
  <HeroTwo />
<!--  <Promo />-->
  <ImgContentThree />
<!--  <FeatureGrid hasTitle="hasTitle" bgLight="bgLight" />-->
<!--  <WorkProcessTwo />-->
  <FeatureEight />

  <CtaTwo />

<!--    <IntegrationOne />-->
  <MyFooter />

</div>
</template>

<script>
import MyFooter from '@/components/footer/CryptoFooter.vue';

import HeroTwo from "@/components/HeroTwo.vue";
// import Promo from "@/components/promo/Promo.vue";
// import WorkProcessTwo from "@/components/work-process/DigiWorkProcess.vue";
// import FeatureGrid from "@/components/features/FeatureGrid.vue";
import ImgContentThree from "@/components/img-content/ImgContentSix.vue";
import CtaTwo from "@/components/cta/CtaOne.vue";
import FeatureEight from "@/components/features/FeatureFive.vue";

export default {
  name: "handleProduct",
  components: {FeatureEight, CtaTwo, ImgContentThree,MyFooter, HeroTwo}
}
</script>

<style scoped>

</style>
