<template>
  <footer class="bg-dark-black crypto-footer pt-60">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="crypto-footer-widget text-center">
            <h4 class="text-white mb-6 fw-medium " style="padding-bottom: 10px">造物之前，必先造人</h4>
            <div class="">
              <p>
                Copyright © 2022-2023山西栖云科技有限公司. All rights reserved
                <span style="cursor: pointer;margin-bottom: 3px" @click="handleGoto">备案号:晋ICP备2021012726号</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods:{
    handleGoto(){
      window.open(  'https://beian.miit.gov.cn/')
    }
  }
};
</script>

