<template>
  <section class="feature-section-two ptb-60">
    <div class="container">
      <div
        class="row align-items-center justify-content-lg-between justify-content-center"
      >
        <div class="col-lg-6 col-md-12">
          <div class="feature-content-wrap">
            <div class="section-heading"   data-aos="fade-up"
                 data-aos-delay="50">
              <h2>更加简洁的系统页面</h2>
              <p>
                系统移出冗余的操作流程,更加健壮的架构.让其界面更加美观,操作更加简单.
              </p>
            </div>
            <ul class="list-unstyled mb-0">
              <li class="d-flex align-items-start mb-4 box" data-aos="fade-up"
                  data-aos-delay="50">
                <div class="icon-content">
                  <h3 class="h5">费用透明、功能全、支持免费定制</h3>
                  <p>
                    我们不进行抽佣、推广不收费用.功能完善后也会维持在全网最低价。不收额外费用即可进行定制化需求.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4" data-aos="fade-up"
                  data-aos-delay="50">

                <div class="icon-content contentBox box" >
                  <h3 class="h5">独特且简约的设计</h3>
                  <span>
                    系统支持黑白两种主题色，界面简洁.操作简单无脑，让您更快上手.
                  </span>
                </div>
              </li>
              <li class="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up"
                  data-aos-delay="50">
                <div class="icon-content contentBox box" >
                  <h3 class="h5">24/7 免费的技术支持</h3>
                  <p class="text-center">
                    我们提供全天候免费的技术支持,为您的经营保驾护航.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-7">
          <div class="feature-img-wrap">
            <img
              src="@/assets/img/feature-hero-img.svg"
              alt="feature image"
              class="img-fluid rounded-custom"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureFive',
};
</script>
<style lang="scss">
.box{
  padding: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: .6s;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px -1px rgb(0 0 0 / 20%);
  .h3{
    margin-bottom: 5px;
  }
  &:hover {
    box-shadow: none;
    transition: 0.6s;
    opacity: 1 !important;
  }
}
.contentBox{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
