<template>
  <section class="why-choose-us pt-60 pb-120">
    <div class="container">
      <div
        class="row justify-content-lg-between justify-content-center align-items-center"
      >
        <div class="col-lg-5 col-md-7 order-1 order-lg-0">
          <div class="why-choose-img position-relative">
            <img
              src="@/assets/img/feature-hero-img-2.svg"
              class="img-fluid"
              alt="duel-phone"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 order-0 order-lg-1">
          <div class="why-choose-content" >
            <div class="mb-32"               >
              <h2>更加强大的系统服务</h2>
              <p>
                支持经营店铺大部分的场景.
              </p>
            </div>
            <ul  class="list-unstyled d-flex flex-wrap list-two-col">
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>组织架构管理
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>精细的权限管理
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>全面的会员管理
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>小程序定制装修
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>丰富的营销组件
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>丰富的报表系统
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>一键触达组织用户
              </li>
              <li class="py-1">
                <i class="fas fa-check-circle me-2 text-primary"></i>堂食/外卖完美适配
              </li>


            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImgContentSix',
};
</script>
